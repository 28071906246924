<template>
    <div v-if="userRoleIsPermitted([AdminRole.SUPER, AdminRole.ADMIN, AdminRole.CONTROL, AdminRole.CONTROL_DIR])">
        <b-row class="py-3">
            <b-col md="6">
                <h4 class="font-weight-bold">
                    Дашборды
                    <div class="text-muted text-tiny mt-1">
                        <small class="font-weight-normal">Сегодня {{ new Date().toLocaleDateString() }}</small>
                    </div>
                </h4>
            </b-col>
        </b-row>

        <!--        <b-row class="mb-4">-->
        <!--            <filter-select-single label="Год" track-by="year" label-field="year" v-model="filterYear" :options="forFilter.years" :disabled="loading" />-->
        <!--            <filter-date-range label="Дата публикации (Для вне ИС - дата заключения договора)" as-row v-model="filterPurchaseStart" :year="filterYear" :disabled="loading" />-->
        <!--            <filter-select-multiple-with-all-button-->
        <!--                v-model="filterMunicipalities"-->
        <!--                label="Муниципалитеты"-->
        <!--                :options="forFilter.municipalities"-->
        <!--                placeholder="Выберите Муниципалитеты"-->
        <!--                :disabled="isFilterMunicipalitiesDisabled || loading" />-->
        <!--            <filter-select-multiple-with-all-button-->
        <!--                v-model="filterBudgetaryManagers"-->
        <!--                label="ГРБС"-->
        <!--                :options="forFilter.budgetaryManagers"-->
        <!--                placeholder="Выберите ГРБС"-->
        <!--                :disabled="isFilterBudgetaryManagersDisabled || loading" />-->
        <!--            <filter-select-multiple-with-all-button-->
        <!--                v-model="filterNationalProjects"-->
        <!--                label="Национальные проекты"-->
        <!--                :options="forFilter.nationalProjects"-->
        <!--                placeholder="Выберите национальные проекты"-->
        <!--                :disabled="loading" />-->
        <!--        </b-row>-->

        <!--        <customer-analytics />-->
        <!--        <provider-analytics />-->
    </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex';
// import moment from 'moment';
// import CustomerAnalytics from '@/components/analytics/customer-analytics';
// import ProviderAnalytics from '@/components/analytics/provider-analytics';

export default {
    name: 'AnalyticsDashboard',
    // components: { ProviderAnalytics, CustomerAnalytics },
    metaInfo: {
        title: 'Аналитика',
    },
    data() {
        return {
            loading: false,
        };
    },
    created() {
        // this.fetchForFilter();
    },
    // methods: {
    //     ...mapActions('analytics', ['fetchForFilter']),
    // },
    computed: {
        idFrom() {
            return 'datepicker-from-' + this._uid;
        },
        idTo() {
            return 'datepicker-to-' + this._uid;
        },
        // yearBegin() {
        //     return this.filterYear ? moment([this.filterYear]).startOf('year').format('YYYY-MM-DD') : null;
        // },
        // yearEnd() {
        //     return this.filterYear ? moment([this.filterYear]).endOf('year').format('YYYY-MM-DD') : null;
        // },
        // maxDate() {
        //     return this.filterPurchaseStart.to ?? (this.filterYear ? moment([this.filterYear]).endOf('year').format('YYYY-MM-DD') : null);
        // },
        // minDate() {
        //     return this.filterPurchaseStart.from ?? (this.filterYear ? moment([this.filterYear]).startOf('year').format('YYYY-MM-DD') : null);
        // },
        // ...mapGetters('analytics', ['forFilter']),
        // filterYear: {
        //     get() {
        //         return this.$store.state.analytics.filter.year;
        //     },
        //     set(value) {
        //         this.$store.dispatch('analytics/updateFilterYear', value);
        //     },
        // },
        // filterPurchaseStart: {
        //     get() {
        //         return this.$store.state.analytics.filter.purchase_start;
        //     },
        //     set(value) {
        //         this.$store.dispatch('analytics/updateFilterPurchaseStart', value);
        //     },
        // },
        // filterMunicipalities: {
        //     get() {
        //         return this.$store.state.analytics.filter.municipalities;
        //     },
        //     set(value) {
        //         this.$store.dispatch('analytics/updateFilterMunicipalities', value);
        //     },
        // },
        // filterBudgetaryManagers: {
        //     get() {
        //         return this.$store.state.analytics.filter.budgetary_managers;
        //     },
        //     set(value) {
        //         this.$store.dispatch('analytics/updateFilterBudgetaryManagers', value);
        //     },
        // },
        // filterNationalProjects: {
        //     get() {
        //         return this.$store.state.analytics.filter.national_projects;
        //     },
        //     set(value) {
        //         this.$store.dispatch('analytics/updateFilterNationalProjects', value);
        //     },
        // },
        // isFilterMunicipalitiesDisabled() {
        //     return this.filterBudgetaryManagers.length > 0;
        // },
        // isFilterBudgetaryManagersDisabled() {
        //     return this.filterMunicipalities.length > 0;
        // },
    },
};
</script>
